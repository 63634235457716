export enum AccountTypes {
  SDoHProviderAdmin = 10,
  SDoh = 9,
  Physician = 6,
  HomeHealth = 4,
  HPCM = 3,
  BillingOffice = 2,
  AdminHH = 1,
  PostAcuteFacility = 12,
  HospitalCMDischargePlanner = 11,
  DMEPOSProvider = 16,
  ReferralProgramProviderAdmin = 15,
  WCCProviderAdmin = 18
}
