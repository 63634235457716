import { Component, Input, Output, EventEmitter, OnInit, OnDestroy, OnChanges, SimpleChanges } from '@angular/core';
import { DualAccountTypesId } from '@core/constants/pacIm-account-type-id';
import { BusinessType, UserTenants } from '@core/model';
import { TenantsService } from '@core/services';
import { SelectItem } from 'primeng/api';
import { Subject, Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { getAccountTypeOptions } from '@utils/business-type-utilities';

interface HealthPlanSelectItemValue {
  tenantId: string,
  healthPlanName: string
}

@Component({
  selector: 'app-account-information-widget',
  templateUrl: './account-information-widget.component.html',
  styleUrls: ['./account-information-widget.component.scss']
})
export class AccountInformationWidgetComponent implements OnInit, OnDestroy, OnChanges {
  private userIdValue = '';
  private accountTypeIdValue!: number;
  private subs: Subscription = new Subscription();
  private businessType?: BusinessType;

  @Input() set userId(value: string) {
    this.userIdValue = value;
  }

  get userId(): string {
    return this.userIdValue;
  }
  @Input() set accountTypeId(value: number) {
    this.accountTypeIdValue = value;
  }

  get accountTypeId(): number {
    return this.accountTypeIdValue;
  }
  @Output() accountTypeIdNew = new EventEmitter<number>();
  @Output() userAccountInformationChanged = new EventEmitter();
  isLoading = false;
  healthPlans: SelectItem<HealthPlanSelectItemValue>[] = [];
  applicableHealthPlans: SelectItem<HealthPlanSelectItemValue>[] = [];

  healthPlan = '';
  businessTypes: { [key: string]: BusinessType} = {};
  applicableAccountTypes: SelectItem[] = [];
  accountTypes: SelectItem[] = [];
  showAssignProvidersWidget = true;
  hasSelectedProviders = false;

  providersListChanges$: Subject<void> = new Subject<void>();
  accountType$: Subject<number> = new Subject<number>();

  constructor(private tenantsService: TenantsService) {}

  ngOnInit(): void {
    this.isLoading = true;

    this.subs = this.tenantsService.getTenants()
      .pipe(
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe((response: UserTenants) => {
        this.healthPlans = [];
        this.accountTypes = [];
        
        response.businessTypes?.forEach(bt => 
          this.businessTypes[bt.businessTypeID] = bt
          );

        this.businessType = this.businessTypes[this.accountTypeId];

        if (response && response.tenants && response.businessTypes) {
          this.healthPlans = response.tenants.map(el => ({ label: el.name, value: { healthPlanName:el.name, tenantId: el.tenantId} }));
          this.applicableHealthPlans = this.healthPlans.filter(hp => this.healthPlanSupportsBusinessType(hp.label, this.businessType));
          this.accountTypes = getAccountTypeOptions(response.businessTypes);

          this.applicableAccountTypes =
            this.accountTypes.filter(at => !this.healthPlan || this.healthPlanSupportsBusinessType(this.healthPlan, at.value));
        }
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.accountTypeId && !changes.accountTypeId.isFirstChange()) {
      this.isAssignProvidersWidgetShown(this.accountTypeId);
      this.businessType = this.businessTypes[changes.accountTypeId.currentValue];
      this.applicableHealthPlans = this.healthPlans.filter(healthPlan => this.healthPlanSupportsBusinessType(healthPlan.value.healthPlanName, this.businessType));
    }
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  onChangeHealthPlan($event: HealthPlanSelectItemValue): void {
    this.applicableAccountTypes = this.accountTypes.filter(at => this.healthPlanSupportsBusinessType($event.healthPlanName, this.businessTypes[at.value]));
  }

  onChangeAccountType(event: number): void {
    if (event) {
      this.applicableHealthPlans = this.healthPlans.filter(hp => this.healthPlanSupportsBusinessType(hp.value.healthPlanName, this.businessTypes[event]));
      this.accountTypeIdNew.emit(event);
      this.isAssignProvidersWidgetShown(event);
      this.accountType$.next(event);
    }
  }

  onSelectProvider(event: boolean): void {
    this.hasSelectedProviders = event;
  }

  onUserProvidersChanged(): void {
    this.providersListChanges$.next();
    this.userAccountInformationChanged.emit();
  }

  isAssignProvidersWidgetShown(value: number): void {
    this.showAssignProvidersWidget = !DualAccountTypesId.includes(value);
  }

  private healthPlanSupportsBusinessType(tenantName?: string, businessType?: BusinessType): boolean {
    if ((businessType?.businessType?.localeCompare('WCC Provider Admin', undefined, {sensitivity: 'base'}) === 0 
      || (businessType?.businessType?.localeCompare('WCC', undefined, {sensitivity: 'base'}) === 0))
     && tenantName?.localeCompare('Anthem', undefined, {sensitivity: 'base'}) !==0 
      && (tenantName?.localeCompare('Regence', undefined, {sensitivity: 'base'}) !== 0)) {
      return false;
    }
    return true;
  }
}